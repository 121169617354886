/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
import "../styles/global.css";

import { MantineProvider } from "@mantine/core";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [PIXEL_ID, setPIXEL_ID] = useState<string | null>(null);
  const [MEASUREMENT_ID, setMEASUREMENT_ID] = useState<string | null>(null);
  const PIXEL_ID_ADMIN = process.env.NEXT_PUBLIC_META_PIXEL_ID;
  const MEASUREMENT_ID_ADMIN = process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (pageProps?.data) {
          setPIXEL_ID(pageProps.data.analyticsMeta);
          setMEASUREMENT_ID(pageProps.data.analyticsGoogle);
        } else {
          const hostname = pageProps?.url?.split("/")[0];
          const res = await fetch(`/api/brand/${hostname}`);
          const response = await res.json();
          const data = response.data[0];
          setPIXEL_ID(data?.analyticsMeta);
          setMEASUREMENT_ID(data?.analyticsGoogle);
        }
      } catch (error) {
        console.error("error fetching brand:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [pageProps]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (window.fbq) {
        window.fbq("track", "PageView");
      }
    };

    if (!loading && window.fbq) {
      window.fbq("track", "PageView");
      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
    return;
  }, [router.events, loading]);

  if (loading) {
    return <Component {...pageProps} />;
  }

  return (
    <>
      {/* Facebook Pixel */}
      {PIXEL_ID && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${PIXEL_ID}');
              fbq('init', '${PIXEL_ID_ADMIN}');
              fbq('track', 'PageView');
            `,
          }}
        />
      )}

      {/* Google Analytics User Measurement ID */}
      {MEASUREMENT_ID && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`}
          />
          <Script id="google-analytics-script-user" strategy="lazyOnload">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${MEASUREMENT_ID}', {
                page_path: window.location.pathname,
              });
            `}
          </Script>
        </>
      )}

      {/* Google Analytics Admin Measurement ID */}
      {MEASUREMENT_ID_ADMIN && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID_ADMIN}`}
          />
          <Script id="google-analytics-script-admin" strategy="lazyOnload">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${MEASUREMENT_ID_ADMIN}', {
                page_path: window.location.pathname,
              });
            `}
          </Script>
        </>
      )}

      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Component {...pageProps} />
      </MantineProvider>
    </>
  );
};

export default MyApp;
